import { graphql } from "gatsby"
import React from "react"
import SEO from "../components/seo"

import Navbar from "../components/Menu/LifestyleNav"
import NuvoImage from "../components/NuvoImage"
import ImageMeta from "../components/ImageMeta"
import ButtonGroupMap from "../components/Button/ButtonGroupMap"
import TheaterVideo from "../components/Theater/TheaterVideo"
import Form from "../components/Form"
import { PrivacyFooter } from "./lifestyle"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"

const LifestylePatientPage = ({ data, location }) => {
  const {
    metaTitle,
    metaDescription,
    isRobotsIndex,
    phone,
    hero,
    about,
    form,
    quote
  } = data.allLifestylePatientsJson.nodes[0]

  return (
    <>
      <SEO
        title={metaTitle}
        description={metaDescription}
        pathname={location.pathname}
        robots={!isRobotsIndex ? "noindex" : ""}
      />
      <Navbar phone={phone} location={location.pathname} />
      <div className="lifestyle ls__patient buffer">
        <div className="ls__section">
          <div className="columns">
            <div className="column is-3"></div>
            <div className="column">
              <h1
                className="has-text-centered mb-0"
                dangerouslySetInnerHTML={{ __html: hero.heading }}
              />
            </div>
            <div className="column is-3"></div>
          </div>
        </div>

        <div className="ls__section">
          <div className="columns is-vcentered">
            <div className="column is-3"></div>
            <div className="column">
              <NuvoImage useAR cloudName="nuvolum" publicId={quote.image} />
            </div>
            <div className="column is-1"></div>
            <div className="column">
              <p className="mt-2-mobile ls__patient-quote">
                <i className="bold">{`"${quote.text}"`}</i>
              </p>
              <TheaterVideo
                videoUrl={`https://www.youtube.com/watch?v=${hero.youtube.code}`}
                controls
                playing
                buttonClass="ls__theater-btn contained"
              />
            </div>
            <div className="column is-3"></div>
          </div>
        </div>

        <div className="ls__section">
          <div className="columns is-desktop">
            <div className="column is-3"></div>
            <div className="column">
              <h2>{about.heading}</h2>
              <MarkdownViewer markdown={about.blurb} />
            </div>
            <div className="column is-1"></div>
            <div className="column">
              <div className="ls__form-sidebar">
                <Form src={form.url} />
              </div>
            </div>
            <div className="column is-3"></div>
          </div>
        </div>
      </div>
      <PrivacyFooter />
    </>
  )
}

export const lifestylePatientPageQuery = graphql`
  query lifestylePatientPage($title: String!) {
    allLifestylePatientsJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        isRobotsIndex
        phone
        hero {
          heading
          youtube {
            code
            image
          }
        }
        about {
          heading
          blurb
        }
        form {
          url
        }
        quote {
          text
          image
        }
      }
    }
  }
`

export default LifestylePatientPage
